import { inject, Pipe, PipeTransform } from '@angular/core';
import { AvatarApi } from '@ministrary/shared/apis/avatar.api';

@Pipe({
  name: 'downloadAvatar',
  standalone: true
})
export class DownloadAvatarPipe implements PipeTransform {
  private avatarApi = inject(AvatarApi);

  async transform(avatarUrl?: string | File): Promise<string> {
    if (!avatarUrl) return '';
    if (avatarUrl instanceof File) return this.blobToBase64(avatarUrl);
    if (avatarUrl.startsWith('http')) return avatarUrl;

    const { data } = await this.avatarApi.download(avatarUrl);
    return this.blobToBase64(data as Blob);
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }
}
